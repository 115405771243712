
<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <form @submit.prevent="validateAndSubmit" id="formUser" >
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="associate.name" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-3">
          <label for="cpf">CPF</label>
          <input type="text" class="form-control" id="cpf" placeholder="CPF" v-model="associate.cpf">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="titlenumber">Título</label>
          <input type="text" class="form-control" id="titlenumber" placeholder="Título" v-model="associate.titlenumber" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-4">
          <label for="associatenumber">Carteirinha</label>
          <input type="text" class="form-control" id="associatenumber" placeholder="Carteirinha" v-model="associate.associatenumber">
        </div>
        <div class="form-group col-md-4">
          <label for="role">Tipo</label>
          <select class="form-control" v-model="associate.role">
            <option value="dependent">Dependente</option>
            <option value="holder">Títular</option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="associateemail">E-mail</label>
          <input type="text" class="form-control" id="associateemail" placeholder="E-mail" v-model="associate.email" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-4">
          <label for="associatecellphone">Celular</label>
          <input type="text" class="form-control" id="associatecellphone" placeholder="Celular" v-model="associate.cellphone">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="address">Endereço</label>
          <input type="text" class="form-control" id="address" placeholder="Endereço" v-model="associate.address" autofocus="autofocus" required="required">
        </div>
      </div>
      
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" id="inactive" type="checkbox" v-model="associate.inactive">
          <label class="form-check-label" for="inactive">Inativo</label>
        </div>
      </div>
      <div style="float: left;">
        <button v-if="associate.id" type="button" class="btn btn-primary" v-on:click="validateAndSendWelcome">Boas vindas</button>
      </div>
      <div style="float: right;">
        <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button>
        <router-link class="btn btn-default" style="margin-left:10px;" to="/associates">Fechar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert, sDialog } from '../utils/jalerts';

export default {
  props: {
    save: Function,
    showPassword: Boolean,
  },
  data() {
    return {
      associate: {},
      accessProfiles: [],
      validationErrors: [],
    };
  },
  mounted() {
    this.fetchGetAssociate();
  },
  computed: {
    showpassword() {
      return true;
    },
  },
  methods: {
    async fetchGetAssociate() {
      if(!this.$route.params.id) return;
      this.$root.loadingVisible = true;
      const res = await ApiAxios.get(`associates/${this.$route.params.id}`);
      this.associate = res.data.associate;
      this.$root.loadingVisible = false;
    },
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }
      
      this.save( this.associate );
    },
    validateAndSendWelcome() {
      let that = this;
      sDialog({
				text: `Deseja enviar um e-email de boas vindas para o associado?<br><br><small>Uma nova senha será gerada e enviada para o acesso.</small>`,
				confirmFunction() {
					that.sendWelcome();
				},
			})
    },
    sendWelcome() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }
      let id = this.associate.id;
      this.$root.loadingVisible = true;
      ApiAxios.post(`associates/welcome`, { id })
        .then(() => {
          this.$root.loadingVisible = false;
          sAlert({ type: 'success', text: 'E-mail enviado com sucesso.' });
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403 || e.response.status == 400) return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data }); 
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
    },
    validate() {
      const errors = [];
      // const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!pattern.test(this.associate.email)) errors.push("E-mail inválido");

      if (!this.associate.name) errors.push("Nome inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
